#Login {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);

  min-height: -webkit-fill-available;
  /* height: 100vh; */
  background-position: center center;
  background-size: cover;
  overflow: hidden;
}

#Login > img {
  position: relative;
  z-index: 1;
  display: block;
  margin: 30% auto 0 auto;
  width: 50%;
  max-width: 250px;
}

.overlay {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-image: linear-gradient(to top, #000000, rgba(0,0,0,.0));
}

.clear-cache {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 5%;
  color: #fff;
  text-decoration: underline;
  max-width: 80%;
  text-align: center;
  font-size: 10px;
}

.tou-text {
  color: #fff;
  font-size: 12px;
  text-align: center;
  max-width: 80%;
}

.button-container {
  position: absolute;
  width: 100%;
  bottom: 15%;
  left: 0;
  right: 0;
  margin: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  animation: slideUp 500ms ease-out;
}

.button {
  font-family: inherit;
  width: calc(100% - 50px);
  padding: 2.3vh 0;
  border-radius: 50px;
  font-size: 2.3vh;
  border: none;
  outline: none;
  background: linear-gradient(to left, #e84393, #e374a9);
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
}

.button.outline {
  background: transparent;
  border-color: #e374a9;
  border-width: 4px;
  border-style: solid;
  color: #e374a9;
}

.button:active:not(.outline),
.button:focus:not(.outline),
.button:hover:not(.outline) {
  background: #e374a9;
}

@keyframes slideUp {
  from {
    transform: translateY(100px) scale(0.9);
    opacity: 0;
  }
  to {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}