.spinner-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.spinner-wrapper small {
  color: #000;
  font-family: inherit;
  margin-top: 30px;
  max-width: 70%;
  text-align: center;
}

.master-root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  overflow: hidden;
  animation: fade 230ms ease-in-out;
}

@keyframes fade {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  50% {
    opacity: 1;
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.card {
  position: absolute;
  background: #F8F3F3;
  height: 95%;
  max-height: 600px;
  width: 90%;
  max-width: 500px;
  margin: 0 auto;
  transition: box-shadow .3s;
  box-shadow: 0px 2px 7px 0 rgba(0,0,0,.4);
  cursor: pointer;
  border-radius: 5px;
}

.card .overlay {
  background-image: linear-gradient(to top, #e84393, rgba(0,0,0,.0));
  border-radius: 5px;
}

.animate {
  transition: transform .3s;
  box-shadow: none;
}

.inactive {
  box-shadow: none;
}

.card-wrapper {
  width: 100%;
  height: 100%;
  background: #fff;
  background-position: center center;
  background-size: cover;
  border-radius: 5px;
}

.card-wrapper .info-wrapper {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  width: calc(100% - 30px);
  margin: auto;
}

.card-wrapper .name {
  /* background: linear-gradient(to left, #e84393, #e374a9); */
  background: rgba(0,0,0,.5);
  padding: 5px 10px;
  border-radius: 20px;
  color: #fff;
  font-size: 18px;
  display: inline-block;
  margin-left: 5px;
  margin-bottom: 10px;
}

.card-wrapper .bio {
  text-align: left;
  color: #fff;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 20px;
  padding: 10px;
}
