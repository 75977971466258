.Match {
  z-index: 999999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0,.7);
  backdrop-filter: blur(5px);
  animation: fadeMatch 200ms ease-in;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

@keyframes fadeMatch {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.Match .info {
  text-align: center;
  color: #e374a9;
  font-family: inherit;
}

.Match .button-container {
  position: relative;
  margin: 0;
  bottom: 0;
}

.Match .button-container button:not(:last-child) {
  margin-bottom: 30px;
}