.desktop-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.desktop-container h1 {
  text-align: center;
  max-width: 70%;
  font-family: inherit;
  color: #e374a9;
  font-size: 24px;
}