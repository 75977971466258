
* {
  box-sizing: border-box;
}

html {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body, #root {
  overscroll-behavior-y: none;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  min-height: -webkit-fill-available;
  margin: 0;
  font-family: 'Work Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #E0E0E0;
}

.alert {
  /* Hide lib alerts */
  display: none;
}

.icon-glossy {
  color: #e374a9;
}
