#Restart {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#Restart h1 {
  text-align: center;
  max-width: 70%;
  font-family: inherit;
  color: #e374a9;
  font-size: 20px;
  margin-bottom: 40px;
}

#Restart .button i {
  margin-left: 20px;
}