#Onboarding {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #e374a9;
}

#Onboarding .icons {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: space-around;
}

#Onboarding .button {
  margin-bottom: 60px;
}

#Onboarding span {
  color: #fff;
}

#Onboarding span:first-child {
  animation: swipeLeft 1.1s ease-out;
  animation-iteration-count: infinite;
}

#Onboarding span:last-child {
  animation: swipeRight 1.1s ease-out;
  animation-iteration-count: infinite;
}

@keyframes swipeLeft {
  0% {
    transform: translateX(10px);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(10px);
  }
}

@keyframes swipeRight {
  0% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-10px);
  }
}